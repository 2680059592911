



































































import { Component, Vue } from 'vue-property-decorator'
import { minLength, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { isVestcasa } from '@/shareds/utils';

@Component({
	mixins: [validationMixin],
	validations: {
		senha: { required, minLength: minLength(6) },
	},
})
export default class Login extends Vue {
	login = ''
	senha = ''
	loginInvalido = false
	erro = ''
	isVestcasa = isVestcasa
	isLoading = false
	logo = !isVestcasa
		? require('@/assets/almode_256x181.png')
		: require('@/assets/logo-vestcasa.webp')
		
	private async logar(): Promise<void> {
		try {
			this.isLoading = true
			await UserLoginStore.login({
				login: this.login,
				senha: this.senha,
			})
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			this.$router.push('/').catch(() => {})
		} catch (e: any) {
			this.loginInvalido = true
			this.erro = e.message
			throw e
		} finally {
			this.isLoading = false
		}
	}
}
